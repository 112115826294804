import Home from "./components/Home";
import Logos from "./components/Logos";
import Branding from "./components/Branding";
import Website from "./components/Website";
import Presentation from "./components/Presentation";
import Print from "./components/Print";
import Production from "./components/Production";
import SocialMedia from "./components/SocialMedia";
import DigitalArt from "./components/DigitalArt";
import Gallery from "./components/Gallery";
import { Routes, Route, useLocation } from 'react-router-dom';
import { useEffect } from "react";


function usePageViews() {
  let location = useLocation()

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1))
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" })
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    }
  }, [location,])
}

function App() {
  usePageViews();
  return (
    <div>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/logos' element={<Logos />} />
        <Route path='/branding' element={<Branding />} />
        <Route path='/website' element={<Website />} />
        <Route path='/presentation' element={<Presentation />} />
        <Route path='/print' element={<Print />} />
        <Route path='/production' element={<Production />} />
        <Route path='/social_media' element={<SocialMedia />} />
        <Route path='/digital_art' element={<DigitalArt />} />
        <Route path='/gallery' element={<Gallery />} />
      </Routes>
    </div>
  );
}

export default App;
