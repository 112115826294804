import React from 'react';

const Print = () => {
  return (
    <div id='print' name='print' className='w-full min-h-screen h-full bg-[#8c9513] p-20'>
      
    </div>
  );
};

export default Print;
