import React from 'react';

const SocialMedia = () => {
  return (
    <div id='social_media' name='social_media' className='w-full min-h-screen h-full bg-[#edcbb0] p-20'>
      
    </div>
  );
};

export default SocialMedia;
