import React from 'react';

const Gallery = () => {
  return (
    <div id='gallery' name='gallery' className='w-full min-h-screen h-full bg-[#e0573f] p-20'>
      
    </div>
  );
};

export default Gallery;
