import React, { useState } from 'react';
import {
  FaBars,
  FaTimes
} from 'react-icons/fa';
import Logo from '../assets/logo.png';
import {Link} from 'react-router-dom';

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);

  return (
    <div id='nav' className='fixed w-full h-[56px] flex justify-between items-center px-4 bg-[#23323a] text-white z-50'>
      <a href='/'>
        <img src={Logo} alt='Logo' style={{ height: '56px' }} />
      </a>

      {/* menu */}
      <ul className='hidden md:flex'>
        <li>
          <Link to='/'>
            Home
          </Link>
        </li>
        <li>
          <Link to='/#work'>
            Work
          </Link>
        </li>
        <li>
          <Link to='/#about'>
            About
          </Link>
        </li>

      </ul>

      {/* Hamburger */}
      <div onClick={handleClick} className='md:hidden z-50'>
        {!nav ? <FaBars /> : <FaTimes />}
      </div>

      {/* Mobile menu */}
      <ul
        className={
          !nav
            ? 'hidden'
            : 'absolute top-0 left-0 w-full h-screen bg-[#0a192f] flex flex-col justify-center items-center'
        }
      >
        <li className='py-6 text-4xl'>
          <Link onClick={handleClick} to='/'>
            Home
          </Link>
        </li>
        <li className='py-6 text-4xl'>
          {' '}
          <Link onClick={handleClick} to='/#work'>
            Work
          </Link>
        </li>
        <li className='py-6 text-4xl'>
          {' '}
          <Link onClick={handleClick} to='/#about'>
            About
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Navbar;
