import logos from '../assets/projects/1_Logos Thumbnail.jpg'
import branding from '../assets/projects/2_Branding Thumbnail.jpg'
import website from '../assets/projects/3_Website design.jpg'
import presentation from '../assets/projects/4_PPT Thumbnail.jpg'
import print from '../assets/projects/5_Print Thumbnail.jpg'
import production from '../assets/projects/6_Production.jpg'
import social_media from '../assets/projects/7_Social Media.jpg'
import digital_art from '../assets/projects/9_Illustration.jpg'


export const data = [
    {
        id: 1,
        name: "Logos",
        image: logos,
        color: "#577c7b",
        page: "Logos",
    },
    {
        id: 2,
        name: "Branding",
        image: branding,
        color: "#003f5e",
        page: "Branding",
    },
    {
        id: 3,
        name: "Website",
        image: website,
        color: "#027d80",
        page: "Website",
    },
    {
        id: 4,
        name: "Presentation",
        image: presentation,
        color: "#d8c566",
        page: "Presentation",
    },
    {
        id: 5,
        name: "Print",
        image: print,
        color: "#8c9513",
        page: "Print",
    },
    {
        id: 6,
        name: "Production",
        image: production,
        color: "#abcfbe",
        page: "Production",
    },
    {
        id: 7,
        name: "Social Media",
        image: social_media,
        color: "#edcbb0",
        page: "SocialMedia",
    },
    {
        id: 8,
        name: "Digital Art",
        image: digital_art,
        color: "#e69080",
        page: "DigitalArt",
    }, {
        id: 9,
        name: "Gallery",
        image: logos,
        color: "#e0573f",
        page: "Gallery",
    },


]