import React from 'react';

const Production = () => {
  return (
    <div id='production' name='production' className='w-full min-h-screen h-full bg-[#abcfbe] p-20'>
      
    </div>
  );
};

export default Production;
