import React from 'react';
import Work from "./Work";


const Home = () => {
  return (
    <>
    <Work />
    </>
  );
};

export default Home;
