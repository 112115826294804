import React from 'react';
import Navbar from "./Navbar";
import { logo } from "../data/logo.js";

const Logos = () => {

  // projects file
  const project = logo;
  //setProject(data);

  return (
    <div>
      <Navbar />

      <div id='logos' name='logo' className='w-full min-h-screen md:h-full text-gray-300 bg-[#577c7b] pt-20'>
        <div className='max-w-[1000px] mx-auto p-4 justify-center w-full h-full'>

          {/* container for projects */}
          <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4">

            {/* Gird Item */}
            {project.map((item, index) => (
              <a
                href={item.page}
                key={index}

              >

                <div
                  className="block max-w-[18rem] bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
                  <div className="relative overflow-hidden bg-cover bg-no-repeat">
                    <img
                      className=""
                      src={item.image}
                      alt="" />
                  </div>
                </div>



              </a>
            ))}


          </div>
        </div>
      </div>
    </div>
  );
};

export default Logos;
