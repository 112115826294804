import React from 'react';
import Navbar from "./Navbar";
import { branding } from "../data/branding.js";
import { Carousel } from 'flowbite-react';



const Branding = () => {

  // projects file
  const project = branding;
  //setProject(data);

  return (
    <div>
      <Navbar />
      <div id='branding' name='branding' className='w-full min-h-screen md:h-full text-gray-300 bg-[#003f5e] pt-14'>

        <div style={{ height: '80vh', width: '80vw' }} className='m-auto'>
          <Carousel>
            {/* Carousel Item */}
            {project.map((item, index) => (
              <div className='max-h-full'
                key={index}
              >
                <img className='' src={item.image} alt="..." />
              </div>
            ))}
          </Carousel>
        </div>

      </div>
    </div>
  );
};

export default Branding;
