import Brand1 from '../assets/branding/1_ClearView Branding 2.jpg'
import Brand2 from '../assets/branding/2_M+M Letterhead grid.jpg'
import Brand3 from '../assets/branding/3_TCG Branding package.jpg'
import Brand4 from '../assets/branding/4_TCG Business Card mockup.png'

export const branding=[
    {
        id:1,
        name:"Logos",
        image:Brand1,
    },
    {
        id:2,
        name:"Branding",
        image:Brand2,
    },
    {
        id:3,
        name:"Website",
        image:Brand3,
    },
    {
        id:4,
        name:"Presentation Design",
        image:Brand4,
    },

]