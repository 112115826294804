import Logo1 from '../assets/logos/1_89 logo.jpg'
import Logo2 from '../assets/logos/2_89 logo.jpg'
import Logo3 from '../assets/logos/3_Angies caring hearts.jpg'
import Logo4 from '../assets/logos/4_Elite 360 Life.jpg'
import Logo5 from '../assets/logos/5_Grove church 1.jpg'
import Logo6 from '../assets/logos/6_Grove church 2.jpg'
import Logo7 from '../assets/logos/7_JJF Redesign.jpg'
import Logo8 from '../assets/logos/8_JNC Fitness logo.jpg'
import Logo9 from '../assets/logos/9 Lulas antiques.jpg'
import Logo10 from '../assets/logos/10_ lulas closet.jpg'
import Logo11 from '../assets/logos/11_Mystique.jpg'
import Logo12 from '../assets/logos/12_Virtual First.jpg'
import Logo13 from '../assets/logos/13_Diversity inclusion.jpg'
import Logo14 from '../assets/logos/14_Resource 7.jpg'
import Logo15 from '../assets/logos/15_Southern Dental.jpg'
import Logo16 from '../assets/logos/Clear View_Profile Pic.jpg'
import Logo17 from '../assets/logos/HD Scapes .jpg'

export const logo=[
    {
        id:1,
        name:"Logos",
        image:Logo1,
    },
    {
        id:2,
        name:"Branding",
        image:Logo2,
    },
    {
        id:3,
        name:"Website",
        image:Logo3,
    },
    {
        id:4,
        name:"Presentation Design",
        image:Logo4,
    },
    {
        id:5,
        name:"Print",
        image:Logo5,
    },
    {
        id:6,
        name:"Production",
        image:Logo6,
    },
    {
        id:7,
        name:"Social Media",
        image:Logo7,
    },
    {
        id:8,
        name:"Digital Art",
        image:Logo8,
    },{
        id:9,
        name:"Gallery",
        image:Logo9,
    },{
        id:10,
        name:"Logos",
        image:Logo10,
    },
    {
        id:11,
        name:"Branding",
        image:Logo11,
    },
    {
        id:12,
        name:"Website",
        image:Logo12,
    },
    {
        id:13,
        name:"Presentation Design",
        image:Logo13,
    },
    {
        id:14,
        name:"Print",
        image:Logo14,
    },
    {
        id:15,
        name:"Production",
        image:Logo15,
    },
    {
        id:16,
        name:"Social Media",
        image:Logo16,
    },
    {
        id:17,
        name:"Digital Art",
        image:Logo17,
    },


]