import React from 'react';
import Navbar from "./Navbar";
import { website } from "../data/website.js";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Website = () => {

  // projects file
  const project = website;

  const settings = {
    className: "slider variable-width center",
    centerMode: true,
    infinite: true,
    dots: true,
    centerPadding: "60px",
    slidesToShow: 3,
    speed: 500,
    variableWidth: true,
  };

  return (
    <div>
      <Navbar />
      <div id='website' name='website' className='w-full min-h-screen md:h-full text-gray-300 bg-[#027d80] pt-20'>
        <div className='w-10/12 m-auto'>
          <Slider {...settings}>
            {/* Carousel Item */}
            {project.map((item, index) => (
              <div className='max-h-full'
                key={index}
              >
                <img className='max-w-sm sm:max-w-xl lg:max-w-4xl' src={item.image} alt="..." />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Website;
