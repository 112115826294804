import React from 'react';
import SocialIcons from "../components/SocialIcons";
import { HiArrowNarrowRight } from 'react-icons/hi';
import { data } from "../data/data.js";

const Work = () => {

  // projects file
  const project = data;
  //setProject(data);

  return (
    <div id='page-top'>
      <SocialIcons />
      <div id='home' name='home' className='w-full bg-[#23323a] p-20'>
        {/* Container */}
        <div className='max-w-[1000px] mx-auto px-8 justify-center text-center'>
          <h1 className='text-4xl sm:text-7xl font-bold text-[#ccd6f6]'>
            Megan Uphaus
          </h1>
          <h2 className='text-4xl sm:text-7xl font-bold text-[#8892b0]'>
            I'm a Graphic Designer.
          </h2>
          <div className='inline-flex content-center'>
            <a href='/#work'>
              <button className='text-white group border-2 px-6 py-3 my-2 flex items-center hover:bg-pink-600 hover:border-pink-600'>
                View Work
                <span className='group-hover:rotate-90 duration-300'>
                  <HiArrowNarrowRight className='ml-3 ' />
                </span>
              </button>
            </a>
          </div>
        </div>
      </div>
      <div id='work' name='work' className='w-full md:h-full bg-[#23323a]'>
        <div className='max-w-[1000px] mx-auto p-4 justify-center w-full h-full'>

          {/* container for projects */}
          <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4 ">

            {/* Gird Item */}
            {project.map((item, index) => (
              <a
                href={item.page}
                key={index}
              >
                <div
                  className="block rounded-lg group"
                  style={{ backgroundColor: item.color }}>
                  <div className="flex relative overflow-hidden bg-cover bg-no-repeat opacity-30 group-hover:opacity-90 max-h-56">
                    <img
                      className="rounded-t-lg"
                      src={item.image}
                      alt="" />
                  </div>
                  <div className="p-6">
                    <span className="text-2xl font bold text-white tracking-wider ">
                      {item.name}
                    </span>
                  </div>
                </div>
              </a>
            ))}

          </div>
        </div>
      </div>
      <div id='about' name='about' className='w-full h-full bg-[#027d80] p-20'>
        <div className='flex flex-col justify-center items-center w-full h-full'>
          <div className='max-w-[1000px] w-full grid grid-cols-2 gap-8'>
            <div className='sm:text-right pb-8 pl-4'>
              <p className='text-4xl font-bold inline border-b-4 border-pink-600'>
                About
              </p>
            </div>
            <div></div>
          </div>
          <div className='max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4'>
            <div className='sm:text-right text-4xl font-bold'>
              <p>Hi. I'm Megan Uphaus, nice to meet you. Please take a look around.</p>
            </div>
            <div>
              <p>I have a dog named Gizmo who can be a turd.  My Boyfriend Jeff is the greastest person to ever live!  Thanks for checking out my design page.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Commodo sed egestas egestas
                fringilla phasellus faucibus scelerisque eleifend donec.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Work;
