import React from 'react';

const Presentation = () => {
  return (
    <div id='presentation' name='presentation' className='w-full min-h-screen h-full bg-[#d8c566] p-20'>
      
    </div>
  );
};

export default Presentation;
