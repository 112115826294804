import React from 'react';
import {FaLinkedin} from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';
import { BsFillPersonLinesFill } from 'react-icons/bs';

const SocialIcons = () => {

    return (
        <div className='hidden lg:flex fixed flex-col top-[35%] left-0' >
            <ul>
                <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-blue-600'>
                    <a
                        className='flex justify-between items-center w-full'
                        href='https://www.instagram.com/megangraceu/?hl=en'
                    >
                        Linkedin <FaLinkedin size={30} />
                    </a>
                </li>
                <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#6fc2b0]'>
                    <a
                        className='flex justify-between items-center w-full'
                        href='https://www.instagram.com/megangraceu/?hl=en'
                    >
                        Email <HiOutlineMail size={30} />
                    </a>
                </li>
                <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#565f69]'>
                    <a
                        className='flex justify-between items-center w-full'
                        href='https://www.instagram.com/megangraceu/?hl=en'
                    >
                        Resume <BsFillPersonLinesFill size={30} />
                    </a>
                </li>
            </ul>
        </div >
    );
};

export default SocialIcons;