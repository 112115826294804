import Website1 from '../assets/website/1_Website MockUp -CV.jpg'
import Website2 from '../assets/website/2_Website MockUp -Drew.png'
import Website3 from '../assets/website/3_Website MockUp -kOMATSU.png'
import Website4 from '../assets/website/4_GLAMA Icons.png'
import Website5 from '../assets/website/5_ISER Icons.png'
import Website6 from '../assets/website/6_Web banners.png'

export const website=[
    {
        id:1,
        name:"Logos",
        image:Website1,
    },
    {
        id:2,
        name:"Branding",
        image:Website2,
    },
    {
        id:3,
        name:"Website",
        image:Website3,
    },
    {
        id:4,
        name:"Presentation Design",
        image:Website4,
    },
    {
        id:3,
        name:"Website",
        image:Website5,
    },
    {
        id:4,
        name:"Presentation Design",
        image:Website6,
    },

]