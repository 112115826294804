import React from 'react';

const DigitalArt = () => {
  return (
    <div id='digital_art' name='digital_art' className='w-full min-h-screen h-full bg-[#e69080] p-20'>
      
    </div>
  );
};

export default DigitalArt;
